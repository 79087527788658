<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="title" :breadcrumbs="breadcrumbs"/>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <TheSectionsTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "index",
  components: {
    TheSectionsTable: () =>
        import(
            "@/components/dashboardComponents/TheSectionsTable"
            ),
    BaseBreadcrumb: () =>
        import(
            "@/components/commonComponents/BaseBreadcrumb"
            ),
  },
  data () {
    return {
      title: 'Secciones',
      breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Sections",
        disabled: true,
        href: "/dashboard/simulacros/"
      }
    ]
    }
  }
}
</script>

<style scoped>

</style>
